import React from "react";
import Text from "./Text";
import Person from "./Person";
import Emoji from "./emoji";

const About = () => {
  return (
    <>
      <Text as="p" variant="label" colour="accent">
        About me:
      </Text>
      <Person />
      <Text as="p" variant="body">
        I write solid JS, CSS and the REST. I
        lead large design projects, spearhead user-research, and help shape
        design systems from scratch. Let's chat{" "}
        <Emoji label="talk" emoji="💬" />
      </Text>
    </>
  );
};

export default About;
