import React from "react";
import PropTypes from "prop-types";
import Post from "./post";

const Posts = ({ posts }) =>
  posts.map(({ node, index }, i) => (
    <Post
      key={i}
      index={index}
      slug={node.fields.slug}
      title={node.frontmatter.title}
      date={node.frontmatter.date}
      excerpt={node.excerpt}
      tag={node.frontmatter.tag}
    />
  ));

Posts.propTypes = {
  posts: PropTypes.array
};

export default Posts;
