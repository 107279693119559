import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { theme_tokens } from "../tokens/theme";
import { Link } from "gatsby";
import Text from "./Text";

const StyledPost = styled(Link)`
  display: block;
  text-decoration: none;
  margin-bottom: 16px;
  padding-top: 8px;
  padding-bottom: 16px;
  border-bottom: 2px solid
    ${({ theme }) => theme_tokens[theme.mode].deep_background};

  &:active,
  &:focus,
  &:hover {
    outline: 0;
    border-bottom: 2px solid ${({ theme }) => theme_tokens[theme.mode].accent};
    color: ${({ theme }) => theme_tokens[theme.mode].accent};
    cursor: pointer;
  }
`;

const Post = ({ index, slug, title, date, excerpt, tag, theme }) => (
  <StyledPost key={index} to={slug} theme={theme}>
    <Text as="p" variant="note" colour="tertiary">
      {date} | {tag}
    </Text>
    <Text as="h1" variant="title" colour="inherit" bold>
      {title}
    </Text>

    <Text as="p" variant="body">
      {excerpt}
    </Text>
  </StyledPost>
);

Post.propTypes = {
  index: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
  excerpt: PropTypes.string,
  date: PropTypes.string,
  tag: PropTypes.string
};

export default withTheme(Post);
