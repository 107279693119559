import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { theme_tokens } from "../tokens/theme";
import Text from "../components/Text";
import Link from "../components/Link";
import List from "../components/List";

const StyledMarker = styled.svg`
  position: absolute;
  left: 0;
  top: 4px;
`;

const StyledExperience = styled.div`
  position: relative;
  padding-left: 24px;

  &::before {
    content: "";
    position: absolute;
    top: 4px;
    left: 3px;
    bottom: -4px;
    height: 100%;
    width: 2px;
    background-color: ${({ theme, last }) =>
      !last
        ? theme_tokens[theme.mode].deep_background
        : theme_tokens[theme.mode].background};
  }
`;

const Experience = ({ date, company, link, role, learnings, last, theme }) => {
  return (
    <StyledExperience theme={theme} last={last}>
      <StyledMarker
        width="8"
        height="8"
        viewBox="0 0 8 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="4" cy="4" r="3" stroke="#509B9B" stroke-width="2" />
      </StyledMarker>
      <Text variant="note" colour="tertiary">
        {date}
      </Text>
      <Text variant="title" bold skinny>
          {link ? <Link href={link}>{company}</Link> : company}
      </Text>
      <Text variant="title">{role}</Text>
      <List items={learnings} />
    </StyledExperience>
  );
};

Experience.propTypes = {
  date: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  learnings: PropTypes.array.isRequired
};

export default withTheme(Experience);
