import React from "react";
import PropTypes from "prop-types";
import { withTheme } from "styled-components";
import { Container, Row, Column } from "./Grid";
import Text from "./Text";

const Header = ({ title, label }) => {
  return (
    <header>
      <Container>
        <Row>
          <Column fill={2}>
            <Text as="p" variant="label" colour="accent">
              {label}
            </Text>
            <Text as="h1" variant="heading" colour="primary">
              {title}
            </Text>
          </Column>
          <Column fill={1}>
          </Column>
        </Row>
      </Container>
    </header>
  );
};

Header.propTypes = {
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default withTheme(Header);
