import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import { Container, Row, Column } from "../components/Grid";
import App from "../components/App";
import Posts from "../components/posts";
import Header from "../components/Header";
import Text from "../components/Text";
import About from "../components/About";
import Links from "../components/Links";
import Experience from "../components/Experience";

const experiences = [
  {
    last: false,
    date: "Jan 2020 – Present",
    company: "accuRx",
    link: "https://www.accurx.com",
    role: "Lead Product Designer",
    learnings: [
      { text: "Helping build the communication tool for healthcare" },
    ]
  },
  {
    last: false,
    date: "Jul 2019 – Jan 2020",
    company: "Entrepreneur First",
    link: "https://www.joinef.com",
    role: "Senior Product Designer",
    learnings: [
      { text: "Created a lightweight design system from scratch" },
      { text: "Built an internal tool to optimise investment decisions" },
      { text: "Designed a suite of new products to launch to new customers" }
    ]
  },
  {
    last: false,
    date: "Mar 2016 – Jun 2019",
    company: "CharlieHR",
    link: "https://www.charliehr.com",
    role: "Product Designer",
    learnings: [
      { text: "Helped take the company from 0 to 100s of paying customer" },
      { text: "Helped design, build, deliver complete new features" },
      { text: "Helped shape and grow our design system" },
      { text: "Lead small projects and managed other designers" }
    ]
  },
  {
    last: true,
    date: "Apr 2015 – Mar 2016",
    company: "making pretty",
    role: "Frontend Engineer",
    learnings: [
      { text: "Built over 10 customer websites" },
      {
        text:
          "Worked closely with our designer to shape digital products and brand identities"
      },
      {
        text:
          "Pitched for work and presented to multiple stakeholders throughout projects"
      },
      { text: "Helped shape the product offering of our small agency" }
    ]
  }
];

const IndexPage = ({ data }) => (
  <App>
    <SEO
      title="Home"
      keywords={[`product design`, `ui developer`, `ui engineer`]}
    />

    <Header
      label="Product Designer and Frontend Engineer"
      title="Hey, I’m Matt. I design and build digital products for a living."
    />

    <main>
      <Container>
        <Row>
          <Column fill={2}>
            <Row>
              <Column fill={1} of={2}>
                <About />
              </Column>
              <Column fill={1} of={2}>
                <Links />
              </Column>
            </Row>
            <Row spacing={4}>
              <Column>
                <Text variant="label" colour="accent">
                  Thoughts:
                </Text>
                <Posts posts={data.allMarkdownRemark.edges} />
              </Column>
            </Row>
          </Column>
          <Column fill={1}>
            <Text variant="label" colour="accent">
              Work:
            </Text>
            {experiences.map((experience, i) => (
              <Experience
                key={i}
                {...experience}
              />
            ))}
          </Column>
        </Row>
      </Container>
    </main>
  </App>
);

export default IndexPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            tag
          }
          excerpt
        }
      }
    }
  }
`;
