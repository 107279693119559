import React from "react";
import styled, { withTheme } from "styled-components";
import Text from "./Text";
import { theme_tokens } from "../tokens/theme";

const StyledPerson = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Avatar = styled.img`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0;
  margin-right: 8px;
  overflow: hidden;
`;

const Person = ({ theme }) => {
  return (
    <StyledPerson theme={theme}>
      <Avatar src="/matt-grannell.jpg" />
      <Text variant="title" skinny>
        Matt Grannell
      </Text>
    </StyledPerson>
  );
};

export default withTheme(Person);
